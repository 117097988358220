import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import Head from "next/head";
import Script from "next/script";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useRouter } from "next/router";
import CartSuccessModal from "../Components/Element/CartSuccessModal";
import ErrorBoundry from "../Components/ErrorBoundry";
import "../public/assets/scss/app.scss";
import store from "../ReduxToolkit/store";
import CommonModel from "../Components/Element/CommonModel";
import SizeModal from "../Components/Element/SizeModal";
import YoutubeModal from "../Components/FashionDemo/YoutubeModal";
import DeleteModal from "../Components/Pages/UserDashboard/DeleteModal";
import ConfirmDeleteModal from "../Components/Pages/UserDashboard/ConfirmDeleteModal";
import CopyConfigModal from "../Layout/Common/Customizer/CopyConfigModal";
import { useLoadScript } from "@react-google-maps/api";
import { selectAuth, selectLoginToken, signOut } from "../ReduxToolkit/Slices/LoginSlice";
import { checkLoginStatus } from "../Service/FetchApi";
import { useDispatch, useSelector } from "react-redux";
import SubmitModal from "../Components/SubmitModal";
import { getSubmitPopupData } from "../Service/FetchApi";
import { SUBMIT_FORM_SELECT_VALUES } from "../Utils/constants";

const libraries = ["places"];

function MyAppContent({ Component, pageProps }) {
    const auth = useSelector(selectAuth)
    const router = useRouter();
    const dispatch = useDispatch();
    const loginToken = useSelector(selectLoginToken);
    const [domain, setDomain] = useState("");
    const [isAddModal, setIsAddModal] = useState({
        isOpen: false,
    });
    const [successModal, setSuccessModal] = useState({
        isOpen: false,
        message: "",
        isErr: false
    });

    useEffect(() => {
        setDomain(window.location.origin);
    }, []);

    useEffect(() => {
        const getShowPopupData = async () => {
            const res = await getSubmitPopupData(loginToken);

            if (res.show_modal && !auth) {
                setIsAddModal((prevState) => ({
                    ...prevState,
                    isOpen: true,
                    accountNumber: res.account_number,
                    phone_number: res.phone_number,
                    position: SUBMIT_FORM_SELECT_VALUES.includes(res.position) ? res.position : '',
                    city: res.city,
                    state: res.state,
                    email: res.email,
                    billingAddress: res.billing_address,
                    billing_address_2: res.billing_address_2,
                    companyName: res.company_name,
                    contactName: res.contact_name,
                }));
            }
        };

        getShowPopupData();
    }, [router.pathname, auth]);

    const pathArr = router.pathname.split("/");
    const titleName = pathArr[
    pathArr.length - (pathArr[pathArr.length - 1][0] === "[" ? 2 : 1)
        ]
        .split("_")
        .map(
            (data) =>
                data
                    .split()
                    .map((char, i) => char.charAt(0).toUpperCase() + char.slice(1)) +
                ""
        );

    // Automatic logout on authentication failure
    useEffect(() => {
        const verifyAuthentication = async () => {
            if (loginToken?.token) {
                const response = await checkLoginStatus(loginToken);
                if (!response.success) {
                    // Log out user by clearing data without redirecting
                    dispatch(signOut());
                    localStorage.clear();
                    router.push("/sign-in/");
                }
            }
        };

        verifyAuthentication();
    }, [loginToken, dispatch]);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyDhBcYvcKqpiXO3jOcUX8erzH2491GOLjc",
        libraries,
    });

    // Close Submit Modal
    const handleCloseModal = () => {
        setIsAddModal((prevState) => ({ ...prevState, isOpen: false }));
    };

    // Open Success Modal
    const handleOpenSuccessModal = (message, isErr) => {
        setSuccessModal({ isOpen: true, message, isErr });
    };

    // Close Success Modal
    const handleCloseSuccessModal = () => {
        setSuccessModal({ isOpen: false, message: "" });
    };

    return (
        <>
            <Head>
                <title>{titleName}</title>
                <link
                    rel="icon"
                    type="image/x-icon"
                    href={`/assets/images/koalogo.png`}
                />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
                <link
                    id="rtl-link"
                    rel="stylesheet"
                    type="text/css"
                    href="/assets/css/bootstrap.min.css"
                />
                <link rel="canonical" href={`${domain}${router.asPath}`} />
                <link
                    href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
                    rel="stylesheet"
                />
                <link
                    href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap"
                    rel="stylesheet"
                />
                <meta
                    name="google-site-verification"
                    content="jsrLdK6AIfFp5ne1rmK0LzERfE0QJn7KIOxSVf671zE"
                />
            </Head>

            <Script
                id="gtm-script"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','GTM-PZSP4RW4');
                    `,
                }}
            />
            <noscript>
                <iframe
                    src="https://www.googletagmanager.com/ns.html?id=GTM-PZSP4RW4"
                    height="0"
                    width="0"
                    style={{ display: "none", visibility: "hidden" }}
                ></iframe>
            </noscript>

            <ErrorBoundry>
                {/*<div*/}
                {/*    style={{*/}
                {/*        backgroundColor: '#d9003c', // Red background*/}
                {/*        color: '#ffffff',          // White text*/}
                {/*        textAlign: 'center',*/}
                {/*        padding: '10px 0',*/}
                {/*        fontSize: '14px',*/}
                {/*        fontWeight: 'bold',*/}
                {/*        textTransform: 'uppercase',*/}
                {/*    }}*/}
                {/*>*/}
                {/*    *STAGING Environment* *STAGING Environment* *STAGING Environment**/}
                {/*</div>*/}
                <Component {...pageProps} loadError={loadError} isLoaded={isLoaded}/>
                <CartSuccessModal/>
                <ToastContainer/>
                <SizeModal/>
                <CommonModel/>
                <DeleteModal/>
                <ConfirmDeleteModal/>
                <YoutubeModal/>
                <CopyConfigModal/>

                {isAddModal.isOpen &&
                    createPortal(
                        <SubmitModal
                            handleCloseModal={handleCloseModal}
                            handleOpenSuccessModal={handleOpenSuccessModal}
                            isAddModal={isAddModal}
                        />,
                        document.body
                    )}

                {successModal.isOpen &&
                    createPortal(
                        <div className="success__popup__wrapper">
                            <div className="success__popup__content">
                <span
                    className="close__icon"
                    onClick={handleCloseSuccessModal}
                >
                    &times;
                </span>
                                {
                                    !successModal.isErr && <h3>Success</h3>
                                }
                                <p>{successModal.message}</p>
                            </div>
                        </div>,
                        document.body
                    )}
            </ErrorBoundry>
        </>
    );
}

export default function MyApp(props) {
    return (
        <Provider store={store}>
            <MyAppContent {...props} />
        </Provider>
    );
}
