import * as Yup from "yup";

// Function to trim leading spaces from a string
export function ltrim(str) {
    if (!str) return str;
    return str.replace(/^\s+/g, '');
}

export const SUBMIT_FORM_VALIDATION_SCHEMA = Yup.object({
    email: Yup.string()
        .matches(
            /^[^\s!%+=]+@[^\s!%+=]+\.[^\s!%+=]+$/,
            "Email must be a valid format and must not contain the characters !, %, +, or ="
        )
        .required("Email is required"),
    phone: Yup.string()
        .matches(
            /^\d{3}-\d{3}-\d{4}$/,
            "Phone number must be in the format XXX-XXX-XXXX"
        )
        .required("Phone number is required"),
    position: Yup.string().required("Please select a position/department"),
});


export const SUGMIT_FORM_INITIAL_VALUE = {
    email: '',
    phone: '', // Must be in XXX-XXX-XXXX format
    position: '',
};
