import { useFormik } from "formik";
import { useSelector } from "react-redux";

import { selectLoginToken } from "../../ReduxToolkit/Slices/LoginSlice";
import {
    SUBMIT_FORM_VALIDATION_SCHEMA,
    SUGMIT_FORM_INITIAL_VALUE,
} from "../../schema/submitPopupSchema";
import { SUBMIT_FORM_SELECT_VALUES } from "../../Utils/constants";
import { sendSubmitFormData } from "../../Service/FetchApi";

import SelectElement from "../Element/SelectElement";
import { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

const SubmitModal = ({ isAddModal, handleCloseModal, handleOpenSuccessModal }) => {
    const loginToken = useSelector(selectLoginToken);
    const [loading, setLoading] = useState(false);
    const [checkboxState, setCheckboxState] = useState({
        spanish: false,
        english: true,
        optIn: true,
        optOut: false
    })
 
    // Format phone number as XXX-XXX-XXXX
    const formatPhoneNumber = (value) => {
        if (!value) return "";
        const cleaned = value.replace(/\D/g, ""); // Remove non-numeric characters
        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

        if (match) {
            const [, part1, part2, part3] = match;
            return [part1, part2, part3].filter(Boolean).join("-");
        }

        return value;
    };


    const formik = useFormik({
        initialValues: {
            email: isAddModal.email || SUGMIT_FORM_INITIAL_VALUE.email,
            phone: isAddModal.phone_number || SUGMIT_FORM_INITIAL_VALUE.phone,
            position: isAddModal.position || SUGMIT_FORM_INITIAL_VALUE.position,
        },
        validationSchema: SUBMIT_FORM_VALIDATION_SCHEMA,
        onSubmit: async (values) => {
            setLoading(true)
            const data = await sendSubmitFormData(loginToken, {
                email: values.email,
                phone_number: values.phone, // Already formatted
                position: values.position,
                language: checkboxState.english ? 'english' : 'spanish',
                marketing: checkboxState.optIn ? 'opt-in' : 'opt-out'
            });

            
            if (data?.success) {
                handleCloseModal(); // Close the current modal
                handleOpenSuccessModal(data.message || "Data updated successfully."); // Open the success modal
            } else if(data?.message) {
                handleCloseModal();
                handleOpenSuccessModal(data?.message, true)
            }

            setLoading(false);
        },
    });

    const handleSubmitForm = (e) => {
        e.preventDefault();
        if(!formik.errors.position && !formik.errors.email && !formik.errors.phone) {
            formik.handleSubmit();
        }
    };

    const handleChangeCheckbox = (name, value) => {
        setCheckboxState(prevState => {
            if(name === 'english') {
                return {
                    ...prevState,
                    [name]: value,
                    spanish: !value
                }
            } else if(name === 'spanish') {
                return {
                    ...prevState,
                    [name]: value,
                    english: !value
                }
            } else if(name === 'optIn') {
                return {
                    ...prevState,
                    [name]: value,
                    optOut: !value
                }
            } else if(name === 'optOut') {
                return {
                    ...prevState,
                    [name]: value,
                    optIn: !value
                }
            }
        })
    };

    return (
        <div className="submit__popup__wrapper">
             {loading && (
                <Backdrop sx={{
                    // position:"absolute",
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 5000,
                    backgroundColor: "rgba(255, 255, 255, 0.3)"
                }} open>
                    <CircularProgress color="primary"/>
                </Backdrop>
            )}
            <div className="body__wrapper">
                <div className="submit__top__wrapper">
                    <h2>Dealer Information Update</h2>
                    <span>
                        It's been awhile. Please check the information below to ensure
                        we have the most up-to-date information.
                    </span>
                </div>
                <div className="info__section">
                    <div className="left__wrapper">
                        <span>Account Number:</span>
                        <span>Contact Name:</span>
                        <span>Company Name:</span>
                        <span>Billing Address:</span>

                    </div>
                    <div className="right__wrapper">
                        <span>{isAddModal.accountNumber}</span>
                        <span>{isAddModal.contactName}</span>
                        <span>{isAddModal.companyName}</span>
                        <span>{isAddModal.billingAddress +' ' + isAddModal.city+' ' + isAddModal.state}</span>

                    </div>
                </div>
                <div className="form__wrapper">
                    <div className="title__section">
                        <p>Contact Information</p>
                    </div>
                    <form onSubmit={handleSubmitForm}>
                        <div id="fields__wrapper">
                            <div id="fealds__label__wrapper">
                                <span>Email Address/User Name </span>
                                <span id="star">*</span>
                            </div>
                            <input
                                placeholder="Enter Your Email Address"
                                type="email"
                                name="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email} // Pre-filled or user-input value
                            />
                            {formik.touched.email && formik.errors.email && (
                                <span className="err__message">{formik.errors.email}</span>
                            )}
                        </div>
                        <div id="fields__wrapper">
                            <div id="fealds__label__wrapper">
                                <span>Phone Number </span>
                                <span id="star">*</span>
                            </div>
                            <input
                                placeholder="XXX-XXX-XXXX"
                                type="text"
                                name="phone"
                                value={formik.values.phone} // Pre-filled or user-input value
                                onChange={(e) => {
                                    const formattedValue = formatPhoneNumber(e.target.value);
                                    formik.setFieldValue("phone", formattedValue, true); // Validate immediately
                                }}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.phone && formik.errors.phone && (
                                <span className="err__message">{formik.errors.phone}</span>
                            )}
                        </div>
                        <div className="field-wrapper">
                            <SelectElement
                                label="Position/Department"
                                isRequire={true}
                                values={SUBMIT_FORM_SELECT_VALUES}
                                formikData={formik}
                                defaultValue="Select Position/Department"
                            />
                            {formik.errors.position && (
                                <span className="err__message">{formik.errors.position}</span>
                            )}
                        </div>
                        <div className="checkbox__wrapper">
                            <div className="inner__box__wrapper">
                                <span>Preferred Language for website and marketing materials.</span>
                                <div className="settings__wrapper">
                                    <div className="inputs__wrapper">
                                        <Checkbox 
                                            sx={{
                                                color: "#9b9999",  
                                                "&.Mui-checked": {
                                                    color: "#b62427",  
                                                },
                                                width: 40, 
                                                height: 40, 
                                                borderRadius: "5px",  
                                            }}
                                            onChange={(e, value) => handleChangeCheckbox('english', value)}
                                            checked={checkboxState.english}
                                        />
                                        <span>English</span>
                                    </div>
                                    <div className="inputs__wrapper">
                                        <Checkbox 
                                            sx={{
                                                color: "#9b9999",  
                                                "&.Mui-checked": {
                                                    color: "#b62427",  
                                                },
                                                width: 40, 
                                                height: 40, 
                                                borderRadius: "5px",  
                                            }}
                                            onChange={(e, value) => handleChangeCheckbox('spanish', value)}
                                            checked={checkboxState.spanish}
                                        />
                                        <span>Spanish</span>
                                    </div>
                                </div>
                            </div>
                            <div className="inner__box__wrapper">
                                <span>Opt-in for marketing and promotional content?</span>
                                <div className="settings__wrapper">
                                    <div className="inputs__wrapper">
                                        <Checkbox 
                                            sx={{
                                                color: "#9b9999",  
                                                "&.Mui-checked": {
                                                    color: "#b62427",  
                                                },
                                                width: 40, 
                                                height: 40, 
                                                borderRadius: "5px",  
                                            }}
                                            onChange={(e, value) => handleChangeCheckbox('optIn', value)}
                                            checked={checkboxState.optIn}
                                        />
                                        <span>Opt-in</span>
                                    </div>
                                    <div className="inputs__wrapper">
                                        <Checkbox 
                                            sx={{
                                                color: "#9b9999",  
                                                "&.Mui-checked": {
                                                    color: "#b62427",  
                                                },
                                                width: 40, 
                                                height: 40, 
                                                borderRadius: "5px",  
                                            }}
                                            onChange={(e, value) => handleChangeCheckbox('optOut', value)}
                                            checked={checkboxState.optOut}
                                        />
                                        <span>Opt-out</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="btn__wrapper">
                            <button type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SubmitModal;
