import { useEffect, useRef, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";

const SelectElement = ({
    label,
    isRequire,
    values,
    formikData,
    defaultValue
}) => {
    const menuRef = useRef(null);
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [showValue, setShowValue] = useState(null);
    const [isOutOfView, setIsOutOfView] = useState(false);

    useEffect(() => {
        checkIfOutOfView()
    }, [isOpenMenu]);

    const checkIfOutOfView = () => {
        if (menuRef.current) {
            const rect = menuRef.current.getBoundingClientRect(menuRef.current);
            setIsOutOfView((rect.bottom + menuRef.current.scrollHeight) > window.innerHeight);
        }
    };

    const handleToggleMenu = () => {
        setIsOpenMenu(prevState => !prevState)
    };

    const handleSelectElement = (value) => {
        setShowValue(value);

        if(formikData) {
            formikData.setFieldValue('position', value)
        }

        handleToggleMenu();
    };
    return (

        <div className="custom__select__wrapper">
            <div className="label__wrapper">
                <p>{label} {isRequire && <span>*</span>}</p>
            </div>
            <div className="field__wrapper">
                <div onClick={handleToggleMenu} className="input__wrapper">
                    <div className="left__side">
                        <input value={formikData.values.position} type="text" />
                        <span style={{ fontWeight: "normal" }}>{formikData.values.position || defaultValue}</span>
                    </div>
                    <div style={{
                        transform: `${isOpenMenu ? 'rotate(180deg)' : 'rotate(0deg)'}`
                    }} className="icon__wrapper">
                        <MdKeyboardArrowDown />
                    </div>
                </div>
                <div style={{
                    top: isOutOfView ? '-203px' : '40px'
                }} ref={menuRef} className={`drop__down ${isOpenMenu ? 'active' : ''}`}>
                    <ul>
                        {
                            values.map(value => {
                                return <li style={{ fontWeight: "normal" }} onClick={() => handleSelectElement(value)}>{value}</li>
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
};

export default SelectElement